// Swiper.js
import Swiper from 'swiper';
import { Navigation, Pagination, A11y, FreeMode, Thumbs } from 'swiper/modules';

const carouselSwiper = new Swiper('.carousel__swiper', {
    modules: [Navigation, Pagination, A11y],
    loop: true, 
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    autoHeight: true, 
    pagination: {
        el: '.carousel__swiper-pagination', 
        clickable: true
    },
    navigation: {
        nextEl: '.carousel__swiper-btn-next', 
        prevEl: '.carousel__swiper-btn-prev',
    }, 
    a11y: {
        enabled: true, 
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
    },
});


const testimonialsCarouselSwiper = new Swiper('.testimonials-carousel__swiper', {
    modules: [Navigation, Pagination, A11y],
    loop: true, 
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    autoHeight: true, 
    pagination: {
        el: '.testimonials-carousel__swiper-pagination', 
        clickable: true
    },
    navigation: {
        nextEl: '.testimonials-carousel__swiper-btn-next', 
        prevEl: '.testimonials-carousel__swiper-btn-prev',
    }, 
    a11y: {
        enabled: true, 
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
    },
});


// WooCommerce Product Image Gallery (product-image.php)
// !! Thumbs swiper must be initialised first
const productGalleryThumbnails = new Swiper('.debra-store__product-gallery-thumbnails', {
    modules: [Navigation, FreeMode, Thumbs],
    spaceBetween: 8,
    slidesPerView: 5.5,
    freeMode: true,
    watchSlidesProgress: true,
    navigation: {
        nextEl: ".debra-store__product-gallery-thumbnails-btn-next",
        prevEl: ".debra-store__product-gallery-thumbnails-btn-prev",
    },
    a11y: {
        enabled: true, 
        prevSlideMessage: 'Previous',
        nextSlideMessage: 'Next',
    },
    breakpoints: {
        1200: {
            slidesPerView: 6.5,
        }, 
    },
});

const productGalleryMain = new Swiper('.debra-store__product-gallery-main', {
    modules: [Navigation, FreeMode, Thumbs, A11y],
    spaceBetween: 0, 
    autoHeight: true, 
    navigation: {
        nextEl: ".debra-store__product-gallery-main-btn-next",
        prevEl: ".debra-store__product-gallery-main-btn-prev",
    },
    thumbs: {
        swiper: productGalleryThumbnails,
    },
    a11y: {
        enabled: true, 
        prevSlideMessage: 'Previous image',
        nextSlideMessage: 'Next image',
    },
});

// Product Gallery Modal
const productGalleryModalImg = document.querySelector('#productGalleryFull');

if ( productGalleryModalImg ) {
    const productGalleryToggles = document.querySelectorAll('.debra-store__product-gallery-main-slide-toggle');
    const productGalleryModal = document.querySelector('#productGalleryModal');
    const productGalleryModalClose = document.querySelector('.debra-store__product-gallery-modal-close');

    productGalleryToggles.forEach(toggle => {
        toggle.addEventListener('click', (e) => {
            e.preventDefault();
    
            const modalImageUrl = toggle.dataset.imageurl;
    
            productGalleryModalImg.src = modalImageUrl;
            productGalleryModal.classList.add('debra-store__product-gallery-modal--active', 'show');
            productGalleryModal.setAttribute('aria-hidden', 'false');
        });
    });

    productGalleryModalClose.addEventListener('click', (e) => {
        e.preventDefault();

        if ( productGalleryModal.classList.contains('debra-store__product-gallery-modal--active') ) {
            productGalleryModal.classList.remove('debra-store__product-gallery-modal--active', 'show');
            productGalleryModal.setAttribute('aria-hidden', 'false');
            productGalleryModalImg.src = '';
        }
    });

    productGalleryModal.addEventListener('click', () => {
        if ( productGalleryModal.classList.contains('debra-store__product-gallery-modal--active') ) {
            productGalleryModal.classList.remove('debra-store__product-gallery-modal--active', 'show');
            productGalleryModal.setAttribute('aria-hidden', 'false');
            productGalleryModalImg.src = '';
        }
    });

    document.addEventListener('keydown', (event) => {  
    
        if (event.key === 'Escape') {
            if ( productGalleryModal.classList.contains('debra-store__product-gallery-modal--active') ) {
                productGalleryModal.classList.remove('debra-store__product-gallery-modal--active', 'show');
                productGalleryModal.setAttribute('aria-hidden', 'false');
                productGalleryModalImg.src = '';
            }
        }
    });
}