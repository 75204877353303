// Forces ticket fields #1 to be required regardless of the setting in the CMS
const ticketForm = document.querySelector('form.product-type-ticket-product');
const ticketRows = document.querySelector('.wc-box-office-ticket-form');
const ticketFields1 = document.querySelector('.wc-box-office-ticket-fields[data-index="0"]');
const addToCart = document.querySelector('.single_add_to_cart_button');

document.addEventListener("DOMContentLoaded", function() {
    
    if (ticketForm) {
        var ticketFields1Inputs = ticketFields1.querySelectorAll(".ticket-field-input");
        
        setTicketsRequired(ticketFields1Inputs);

        watchTicketQuantity();

        setTimeout( () => {
            addToCart.removeAttribute("disabled");
            addToCart.classList.remove("disabled");
        }, 1000);
    }
});

// Force (specified) ticket fields to be required
function setTicketsRequired(ticketinputs) {

    ticketinputs.forEach(input => {
        if (!input.hasAttribute("required", "required")) {
            input.setAttribute("required", "required");

            var label = input.previousElementSibling;

            label.classList.add("required-field");
            label.insertAdjacentHTML("beforeend", "<span class='required'>*</span>");
        }
    });
}

// When rows are added with the quantity input - see watchTicketQuantity() - update these
function updateAddedRows() {

    // Make all subsequent rows not required
    var ticketFields = document.querySelectorAll(".wc-box-office-ticket-fields[data-index]:not(.wc-box-office-ticket-fields[data-index='0'])");

    ticketFields.forEach(fields => {
        var ticketFieldsInputs = fields.querySelectorAll(".ticket-field-input");

        ticketFieldsInputs.forEach(input => {
            input.removeAttribute("required", "required");

            var label = input.previousElementSibling;
            var labelMarker = label.querySelector(".required");
    
            label.classList.remove("required-field");
            if (labelMarker) {
                labelMarker.remove();
            }
        });
    });
}

function watchTicketQuantity() {

    var config = { childList: true };

    var callback = function(mutationsList) {
        for(var mutation of mutationsList) {
            if (mutation.type == 'childList') {
                updateAddedRows();
            }
        }
    };

    var observer = new MutationObserver(callback);
    observer.observe(ticketRows, config);
}