const shopSelectorToggle = document.querySelector('.furniture-collection__shop-card-shop-selector-toggle');
const shopSelector = document.querySelector('.furniture-collection__shop-card-shop-selector');
const shopSelectorMenu = document.querySelector('#shop_selector');

function toggleShopSelector() {

    e.preventDefault();

    if (shopSelector.classList.contains("furniture-collection__shop-card-shop-selector--active")) {
        shopSelector.classList.remove("furniture-collection__shop-card-shop-selector--active");
        shopSelector.setAttribute("aria-hidden", "true");
    } else {
        shopSelector.classList.add("furniture-collection__shop-card-shop-selector--active");
        shopSelector.setAttribute("aria-hidden", "false");
    }
}

if (shopSelectorToggle && shopSelectorMenu) {
    shopSelectorToggle.addEventListener("click", toggleShopSelector);


    shopSelectorMenu?.addEventListener('change', () => {

        var selectedShop = shopSelectorMenu.value;

        window.location = selectedShop;

    });
}