const siteBody = document.querySelector("body");
const siteHeader = document.querySelector(".site-header");
const primaryMenu = document.querySelector(".site-header__lower");
const primaryMenubar = primaryMenu.querySelector("div.menu");
const menuToggle = document.querySelector(".site-header__menu-toggle");

function togglePrimaryMenu() {

    if (primaryMenu.classList.contains("site-header__lower--active")) {

        // Close the menu
        primaryMenu.classList.remove("site-header__lower--active")
        menuToggle.classList.remove("site-header__menu-toggle--active")
        siteHeader.classList.remove("site-header--menu-open")
        primaryMenubar.setAttribute("aria-hidden", "true")
        siteBody.classList.remove("site-header--has-menu-open")

    } else {

        // Open the menu
        primaryMenu.classList.add("site-header__lower--active")
        menuToggle.classList.add("site-header__menu-toggle--active")
        siteHeader.classList.add("site-header--menu-open")
        primaryMenubar.setAttribute("aria-hidden", "false")
        siteBody.classList.add("site-header--has-menu-open")
    }
}

menuToggle.addEventListener("click", togglePrimaryMenu);

// if the menu is active, close it on press of Esc.
document.addEventListener('keydown', (event) => {  
    
    if (event.key === 'Escape') {
        if ( primaryMenu.classList.contains("site-header__lower--active") ) {
            primaryMenu.classList.remove("site-header__lower--active")
            menuToggle.classList.remove("site-header__menu-toggle--active")
            siteHeader.classList.remove("site-header--menu-open")
            siteBody.classList.remove("site-header--has-menu-open")
        }
    }
});