/* Post Archive - Filters toggle (small device) */
const filtersForm = document.querySelector(".post-archive__filters");
const filtersToggle = document.querySelectorAll(".post-archive__filter-toggle");

function toggleFiltersForm() {
	if (filtersForm.classList.contains("post-archive__filters--active")) {
		filtersForm.classList.remove("post-archive__filters--active")
		filtersForm.setAttribute("aria-hidden", "true");
	} else {
		filtersForm.classList.add("post-archive__filters--active")
		filtersForm.setAttribute("aria-hidden", "false");
	}
}

filtersToggle.forEach(toggle => {
	toggle.addEventListener("click", toggleFiltersForm);
});

document.addEventListener('keydown', (event) => {    
    if (event.key === 'Escape') {
        if ( filtersForm.classList.contains("post-archive__filters--active") ) {
            filtersForm.classList.remove("post-archive__filters--active")
			filtersForm.setAttribute("aria-hidden", "true");
        }
    }
});

document.addEventListener("DOMContentLoaded", function() {

	// Set the ARIA attribute on load
	const windowWidth = window.innerWidth;
	if (windowWidth < 768) {
		if ( ! filtersForm.classList.contains("post-archive__filters--active") ) {
    		filtersForm.setAttribute("aria-hidden", "true");
		}
	}
});